import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Con2.css';

gsap.registerPlugin(ScrollTrigger);

function Con2() {
  useEffect(() => {
    // GSAP 애니메이션 설정
    const timelines = gsap.utils.toArray(".con2 .listBox .box").map((selector) => {
      return gsap.timeline({
        scrollTrigger: {
          trigger: selector,
          start: "0% 20%",
          end: "0% 0%",
          scrub: 1,
        },
      })
      .to(selector, { transform: "rotateX(-10deg) scale(0.9)", transformOrigin: "top", filter: "brightness(0.3)" }, 0);
    });

    gsap.utils.toArray(".mainTextBox .title i").forEach((selector) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: selector,
          start: "100% 100%",
          end: "100% 100%",
          scrub: 1,
        },
      })
      .fromTo(selector, { opacity: 0, y: 100 }, { opacity: 1, y: 0, ease: "none", duration: 5 }, 0);
    });

    gsap.utils.toArray(".subText p").forEach((selector) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: selector,
          start: "100% 100%",
          end: "100% 100%",
          scrub: 1,
        },
      })
      .fromTo(selector, { opacity: 0, y: 100 }, { opacity: 1, y: 0, ease: "none", duration: 5 }, 0);
    });

    const textElements = gsap.utils.toArray('.text1');

    textElements.forEach(text => {
      gsap.to(text, {
        backgroundSize: '100%',
        ease: 'none',
        scrollTrigger: {
          trigger: text,
          start: 'center 80%',
          end: 'center 20%',
          scrub: true,
        },
      });
    });


    // 컴포넌트 언마운트 시 GSAP 애니메이션 해제
    return () => {
      timelines.forEach(timeline => timeline.kill());
    };
  }, []);

  return (
    <>
    <section className="con2">
      <section id="container4">
        <div className="slide-container">
            <h1 className="text1">
              Outstanding Efficiency 
            </h1>
            <h4>
                Efficient forest clothing is possible with MiON Forest.
              </h4>
          </div>
      </section>
      <div className="inner">
        <ul className="listBox">
                    <li className="box a">
                        <h2>Reduce costs</h2>
                        <h3>1</h3>
                        <div className="text">
                            <h4>- Use drones rather than people to save significantly 
                            <br />- You can make full use of places where people can't go</h4>
                        </div>
                    </li>
                    <li className="box b">
                        <h2>High efficiency</h2>
                        <h3>2</h3>
                        <div className="text">
                        <h4>- Large drones can drop 8 seedlings at once
                            <br />- Effective forest restoration with high survival and fast growth</h4>
                        </div>
                    </li>
                    <li className="box c">
                        <h2>Direct planting</h2>
                        <h3>3</h3>
                        <div className="text">
                        <h4>- Planting by dropping grown seedlings directly
                            <br />- Ensuring High Survival Rates to an Already-Growded Stage</h4>
                        </div>
                    </li>
                    <li className="box a">
                        <h2>AI technology</h2>
                        <h3>4</h3>
                        <div className="text">
                        <h4>- Development of Deep Learning Technology and AI Analysis System 
                            <br />- Recognize the affected area and self-produce the optimal planting method</h4>
                        </div>
                    </li>
                    <li className="box b">
                        <h2>Practicality</h2>
                        <h3>5</h3>
                        <div className="text">
                        <h4>- Easy to operate and manage with dedicated SW  
                            <br />- It can be applied to any country and can be used by beginners</h4>
                        </div>
                    </li>
                    <li className="box c">
                        <h2>Soil microorganisms</h2>
                        <h3>6</h3>
                        <div className="text">
                        <h4>- Microbiological addition for growth promotion and soil health 
                            <br />- Promote eco-friendly forest restoration without environmental pollution</h4>
                        </div>
                    </li>
                </ul>
      </div>
      <section id="leader">
      <div className="leader-content">
        <h1>Our Leadership Team</h1>
      </div>
    </section>
    </section>
    </>
  );
}

export default Con2;
