import React from 'react';
import lg from './images/MiON_Logo.png';
import Typewriter from 'typewriter-effect';

function Loading({ onLoadingComplete }) {
  return (
    <div className="loading">
      <div className="logo">
        <span><img src={lg} alt=""/></span>
      </div>
      <div className="logo1">
        <span>
        <Typewriter
          options={{
            strings: ['The Switch to Activate Microbiome'],
            autoStart: true,
            loop: false,
            delay: 150, // 타이핑 속도 조정 (ms 단위)
            onComplete: () => {
              onLoadingComplete();
            },
          }}
        />
        </span>
        </div>
    </div>
  );
}

export default Loading;
