import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import supporterImg1 from '../images/FL-CMYK.webp';
import supporterImg2 from '../images/McMaster_University_logo.svg.webp';
import supporterImg3 from '../images/UQ Logo.webp';
import supporterImg4 from '../images/iF-Logo_colour-1.webp';
import supporterImg5 from '../images/images.jfif';
import './Con6.css';
gsap.registerPlugin(ScrollTrigger);

function Con6() {

  return (
      <>
        <section id="Supporter">
          <div className="Supporter-content">
            <img src={supporterImg5} alt="Supporter" />
            <img src={supporterImg2} alt="Supporter" />
            <img src={supporterImg1} alt="Supporter" />
            <img src={supporterImg3} alt="Supporter" />
            <img src={supporterImg4} alt="Supporter" />
          </div>
        </section>
      </>
  );
}

export default Con6;
