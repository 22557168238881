// Home03.js
import React from 'react';
import Con1 from './Home03/Con1';
import Con2 from './Home03/Con2';
import Con3 from './Home03/Con3';
import Con4 from './Home03/Con4';
import Con5 from './Home03/Con5';
import Con6 from './Home03/Con6';
import Home from '../pages/Home/Home';
import Contact from '../pages/Home/Contact';

const Home03 = () => {
  return (
    <>
      <Home />
      <Con1 />
      <Con3 />
      <Con4 />
      <Con2 />
      <Con5 />
      <Contact />
      <Con6 />
    </>
  );
};

export default Home03;

// Con2.js, Con3.js, Con4.js도 같은 방식으로 useEffect를 사용해 애니메이션 설정
