import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Con4.css';

gsap.registerPlugin(ScrollTrigger);

function Con4() {

  useEffect(() => {
    // 애니메이션 설정
    const arrow = document.querySelector('#title-container .arrow');
    if (arrow) {
      gsap.to(arrow, { y: 10, repeat: -1, yoyo: true });
    }
  
    const container = gsap.utils.toArray('.container');
    const main = document.querySelector('#main');

    if (container.length > 0 && main) {
      // 컨테이너 애니메이션 설정
      gsap.to(container, {
        xPercent: -100 * (container.length - 1),
        scrollTrigger: {
          trigger: '#main',
          pin: true,
          scrub: 1,
          end: '+=' + main.offsetWidth,
        }
      });

      // 각 컨테이너에 대한 ScrollTrigger 설정
      container.forEach(v => {
        ScrollTrigger.create({
          trigger: v,
          start: 'top top-=' + (v.offsetLeft - window.innerWidth / 2) * (main.offsetWidth / (v.offsetWidth * (container.length - 1))),
          end: '+=' + v.offsetWidth * (main.offsetWidth / (v.offsetWidth * (container.length - 1))),
        });
      });

      // 텍스트 요소 애니메이션 설정
      const textElements = gsap.utils.toArray('.text');
      textElements.forEach(text => {
        gsap.to(text, {
          backgroundSize: '100%',
          ease: 'none',
          scrollTrigger: {
            trigger: text,
            start: '100% 100%',
            end: 'top top',
            scrub: true,
          },
        });
      });
    }

    // 클린업 함수: 애니메이션 및 ScrollTrigger 정리
    return () => {
      gsap.killTweensOf('*'); // 모든 애니메이션 정리
      ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // 모든 ScrollTrigger 정리
    };

  }, []);

  return (
    <>
      <section id="title-container">
        <div className="slide-container">
          <h1 className="text">
            Innovative Solution <br />
            -MiON Forest Tree Planting Drone-
            <span>MiON Forest transforms reforestation with a blend of ecological expertise and advanced engineering.</span>
          </h1>
        </div>
        <div className="arrow">↓</div>
      </section>
      <main id="main">
        <div id="container1" className="container">
          AI-driven Drone
          <h3>Eco-friendly electric drone ensuring no disturbance to soil ecosystems.</h3>
          <h5>The AI-driven tree planting drones facilitate rapid deployment of reforestation projects,<br/> achieving cost savings through efficient planting techniques.</h5>        
        </div>
        <div id="container2" className="container">
          Soil Microbiome Activator
          <h3>Improving root health and seedling survival rates.</h3>
          <h5>We restore degraded ecosystems by planting trees in biodegradable seedling pots enhanced with soil microbiome activators,<br/> which significantly improve seedling survival and growth.</h5>
        </div>
        <div id="container3" className="container">
          Biodegradable Pots
          <h3>Reforestation with a blend of ecological expertise and advanced engineering.</h3>
          <h5>These pots enhance aerodynamics during planting and biodegrade completely,<br/> reducing plastic waste and eliminating the need for unpacking.</h5>
        </div>
      </main>
    </>
  );
}

export default Con4;
