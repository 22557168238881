import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Con1.css';

gsap.registerPlugin(ScrollTrigger);

function Con1() {
  useEffect(() => {

    const arrow = document.querySelector('.arrow');
    
    gsap.to(arrow,{ y:10, repeat:-1, yoyo:true });
    
  }, []);

  return (
    <>
    <section id="con1">
      <div className="container">
        <h1 className="text">
          Mion Forest is a forest restoration company<br /> 
          that combines ecology and advanced engineering <br />
          to provide much more effective forest restoration <br />
          services than conventional methods.
        </h1>
      </div>
    <div className="arrow">↓</div>
  </section>
  </>
  );
}

export default Con1;
