import React, { useState, useEffect } from 'react';
import lg from './images/logo/로고.png';
import { Link } from 'react-router-dom';

function Header() {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll > lastScrollTop && currentScroll > 100) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll); // For Mobile or negative scrolling
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  return (
    <header className={isVisible ? 'visible' : 'hidden'}>
      <div className="inner">
        <a href="#" className="logo">
          <span><img src={lg} alt=""/></span>
        </a>
        <nav className="gnb">
          <ul className="menuBox">
            <li><Link to="/">HOME</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
