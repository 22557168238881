import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./style.css";
import Header from "./components/Header";
import Home03 from "./components/Home03"; 
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToTop from "./components/ScrollToTop"; 

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  useEffect(() => {

    // GSAP 애니메이션 설정
  
    let loading = document.querySelector(".loading");
    let rotate = document.querySelectorAll(".rotate");
    let opacity = document.querySelectorAll(".opacity");

    setTimeout(() => loading.classList.add("scene1"), 0);
    setTimeout(() => loading.classList.add("scene2"), 9500);
    setTimeout(() => loading.classList.remove("scene1", "scene2"), 10500);
    setTimeout(() => rotate.forEach((rotate) => rotate.classList.add("on")), 10500);
    setTimeout(() => opacity.forEach((opacity) => opacity.classList.add("on")), 10500);
  }, []);

  return (
    <Router>
      <div className="wrap">
        <Loading />
        <Header />
        <ScrollToTop /> 
        <Routes>
          <Route path="/" element={<Home03 />} /> 
          <Route path="/#" element={<Home03 />} /> 
          <Route path="/home" element={<Home03 />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
