import React from 'react';
import { createRoot } from 'react-dom/client';  // React 18에서의 새로운 import
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);  // createRoot로 root를 생성
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
