import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

function Contact() {

  return (
    <section id="Contact">
      <div className="contact-us">
        <h1>Contact Us</h1>
        <p>If you have any questions, feel free to reach out to us. We're here to help!</p>
        <div className="contact-info">
          <h2><FontAwesomeIcon icon={faMapMarkerAlt} /> 175 Longwood Rd S Suite 101A</h2>
          <h2><FontAwesomeIcon icon={faPhone} /> +1 (819) 443-6250</h2>
          <h2><FontAwesomeIcon icon={faEnvelope} /> info@mionscience.com</h2>
        </div>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d460.8334818828747!2d-79.8833190984595!3d43.25468326768566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c88ed6f4572f7%3A0x17501e3d8d47280b!2s175%20Longwood%20Rd%20S%20Suite%20101A%2C%20Hamilton%2C%20ON%20L8P%200A1%2C%20Canada!5e0!3m2!1sen!2sus!4v1692459192027!5m2!1sen!2sus"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps"
        ></iframe>
      </div>
    </section>
  );
}

export default Contact;
