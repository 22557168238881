import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Con5.css';

gsap.registerPlugin(ScrollTrigger);

function Con5() {
  useEffect(() => {
    const items = document.querySelectorAll('.list-item1, .list-item2');
     
    items.forEach((v, i) => {
      gsap.set(v, { opacity: 0, x: i % 2 ? 100 : -100 });
      
      gsap.to(v, {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: v,
          start: 'top 90%',
          end: 'bottom',
          toggleActions: "play reverse play reverse",
        },
      });
    });
  }, []);

  return (
    <>      
      <section id="con5">
        <ul className="list">
          <li className="list-item1">
            <span>
              <h1>Sanghyun Kim, PhD (c)</h1>
              <h2>Co-founder & CEO </h2>
              <h4>
                Sanghyun is the driving force behind the company.
                He loves to keep his hands full by participating in ecological research, <br/>
                engineering and startup growth. 
              </h4>
            </span>
          </li>
          <li className="list-item2">
            <span>
            <h1>Dr. Naharin Sultana Anni</h1>
              <h2>Co-founder & COO </h2>
              <h4>
                Anni loves taking on challenges.<br/>
                With his multi-year experience as Medical Doctor in the hospitals, 
                Anni has helped the company to get where it is today.<br/>
                Anni is among the best minds.
              </h4>
            </span>
          </li>
        </ul>
      </section>
    </>
  );
}

export default Con5;
