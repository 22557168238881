import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Home.scss';


gsap.registerPlugin(ScrollTrigger);

export default function Home() {
    return (
        <>
            <section id='mission'>
                    <div>
                        <h1>MiON FORST</h1>
                        <p>
                        Next Generation Forest Restoration Solution
                    </p>
                </div>
            </section>
            <section id='hero'>
                        <div className='items'>
                            <div className='item1'>
                                <div>
                                    <h3>
                                        ADVANCED TREE PLANTING DRONES
                                    </h3>
                                </div>
                            </div>
                            <div className='item2'>
                                <div>
                                    <h3>
                                        ENHANCE OPERATIONAL EFFICIENCY
                                    </h3>
                                </div>
                            </div>
                            <div className='item3'>
                                <div>
                                    <h3>
                                        ECO-FRIENDLY SOIL MICROBIOME ACTIVATORS
                                    </h3>
                                </div>
                            </div>
                            <div className='item4'>
                                <div>
                                    <h3>
                                        IMPROVED DATA COLLECTION AND MONITORING
                                    </h3>
                                </div>
                            </div>
                        </div>
            </section>
        </>
    )
}