import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Con3.css';

gsap.registerPlugin(ScrollTrigger);

function Con3() {
  const [activeText, setActiveText] = useState('');

  useEffect(() => {
    const initializeScrollTrigger = () => {
      let panels = gsap.utils.toArray(".item");

      // ScrollTrigger 생성
      panels.forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          start: "top top",
          end: "bottom top",
          pin: true,
          scrub: 0.5,
          pinSpacing: false,
          onUpdate: self => handleScrollUpdate(self, i)
        });
      });

      const handleScrollUpdate = (scrollTrigger, index) => {
        const startScroll = scrollTrigger.start;
        const endScroll = scrollTrigger.end;
        const currentScroll = scrollTrigger.scroll();

        // 텍스트 변경 로직
        if (currentScroll > startScroll && currentScroll < endScroll) {
          if (index === 0) {
            setActiveText('PROBLEM\n1/3');
          } else if (index === 1) {
            setActiveText('PROBLEM\n2/3');
          } else if (index === 2) {
            setActiveText('PROBLEM\n3/3');
          } else if (index === 3) {
            setActiveText('THEN\nNOW');
          }
        }

        if (currentScroll > startScroll && currentScroll < endScroll) {
          // 원을 보이게 함
          gsap.to('.circle', { opacity: 1 });
        } else {
          // 원을 숨김
          gsap.to('.circle', { opacity: 0 });
        }

        if (scrollTrigger.progress === 1) {
          // 전체 패널이 스크롤된 후 원을 숨김
          gsap.to('.circle', { opacity: 0 });
        }
      };

      gsap.utils.toArray('.txt_wrap dt, .txt_wrap dd').forEach((element) => {
        gsap.fromTo(element,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: element,
              start: 'top 80%',
              end: 'top 30%',
              scrub: true
            }
          }
        );
      });

      ScrollTrigger.refresh();
    };

    const checkElementsExist = () => {
      if (document.querySelectorAll('.item').length && document.querySelectorAll('.txt_wrap dt, .txt_wrap dd').length) {
        initializeScrollTrigger();
      } else {
        setTimeout(checkElementsExist, 100);
      }
    };

    checkElementsExist();

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <>
      <section className="con3">
        <span className="circle"> <h2 className="text">{activeText}</h2></span>
        <div className="l_cont">
          <div className="item cont01">
            <dl className="txt_wrap">
              <dt data-aos="fade-up">serious forest fire damage</dt>
              <dd data-aos="fade-up" data-aos-delay="300">
                <p>Wildfires are bigger and more frequent every year.</p>
                <div className="pl">Climate change is causing wildfires to break out faster. The problem is that the rate of damage is much faster than the rate of recovery.</div>
              </dd>
              <a href="https://ourworldindata.org/wildfires" className="btn" target="_blank" rel="noopener noreferrer">View More</a>
            </dl>
          </div>
          <div className="item cont02">
            <dl className="txt_wrap">
              <dt data-aos="fade-up">the limits of the workforce</dt>
              <dd data-aos="fade-up" data-aos-delay="300">
                <p>Forest restoration is not possible with traditional methods.</p>
                <div className="pl">Traditional methods plant trees by humans themselves. However, they are too expensive and do not have access to deep forest areas.</div>
              </dd>
              <a href="https://therevelator.org/tree-planting-reforestation/" className="btn" target="_blank" rel="noopener noreferrer">View More</a>
            </dl>
          </div>
          <div className="item cont03">
            <dl className="txt_wrap">
              <dt data-aos="fade-up">Ineffectiveness of seedballs</dt>
              <dd data-aos="fade-up" data-aos-delay="300">
                <p>Seedball aerial pitching has low survival rates and has not yet been validated.</p>
                <div className="pl">It takes a long time to grow into a tree, and it is ineffective because the survival rate is low due to damage to wildlife. There is also the problem of wasting many seeds.</div>
              </dd>
              <a href="https://en.wikipedia.org/wiki/Seed_ball" className="btn" target="_blank" rel="noopener noreferrer">View More</a>
            </dl>
          </div>
          <div className="item cont04">
            <dl className="txt_wrap">
              <dt data-aos="fade-up">Innovative Forest Restoration</dt>
              <dd data-aos="fade-up" data-aos-delay="300">
                <p>Now, we need innovative solutions rather than traditional methods.</p>
                <div className="pl">MiON Forest is uniquely positioned to capitalize on these trends and transform the industry through our cutting-edge drone technology, microbiome-activated seedling pots, and deep ecological expertise.</div>
              </dd>
              <a href="https://docs.google.com/document/d/18qaXxRX3gl96nY5z_67EbkG1aaLDaqEas6uZnOQptwA/edit?usp=sharing" className="btn" target="_blank" rel="noopener noreferrer">View More</a>
            </dl>
          </div>
        </div>

        <div className="r_cont">
          <div className="item cont01"></div>
          <div className="item cont02"></div>
          <div className="item cont03"></div>
          <div className="item cont04"></div>
        </div>
      </section>
    </>
  );
}

export default Con3;
